<script>
// import Error404 from "@/views/crafted/authentication/Error404.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
export default {
  components: {
    // Error404,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const menu = computed(() => store.getters.menu);
    // economato-societa
    //

    const routeEconomatoSocieta = menu.value[0]
      ? menu.value[0].pages[1].route.replace(
          "anagrafica-societa",
          "economato-societa"
        )
      : "/societa";
    // const route = useRoute();
    // const id_societa = ref(route.params.id);
    Swal.fire({
      html: "Pagamento fallito",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "btn fw-bold btn-light-danger",
      },
    }).then(() => router.push(routeEconomatoSocieta));
    return { routeEconomatoSocieta };
  },
};
</script>

<style></style>
